// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

import DataTable from 'react-data-table-component';

// Data
import { EditOutlined } from '@ant-design/icons';
import { CircularProgress } from '@mui/material';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import AddSubscriptionForm from 'layouts/addSubscription/index';
import EditSubscriptionForm from 'layouts/editSubcription/index';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { openNotificationWithIcon, URL_WITH_VERSION } from 'shared';

function CompanyList() {
  const [state, setState] = useState({
    columns: [],
    rows: [],
    editData: {},
    isView: false,
  });
  const [isAddCompany, setIsAddComapny] = useState(false);
  const [filterText, setfilterText] = useState('');
  const [pending, setPending] = useState(true);
  const [filterData, setFilterdata] = useState([]);
  const [count, setCount] = useState(0)
  const [isAddSub, setIsAddSub] = useState(false)
  const handleSearch = (text) => {

    let data = state?.rows?.filter((item) =>
      JSON.stringify(item).toLocaleLowerCase().includes(filterText.toLowerCase()),
    ) || [];
    setFilterdata(data)
  }

  const onCancel = () => {
    setState((prevState) => ({ ...prevState, editData: undefined, isView: false }));
    setIsAddComapny(false)
    setIsAddSub(false)
    setCount((prevCount) => prevCount + 1);
  }



  const columns = [
    {
      name: <strong>Company name</strong>,
      selector: (row) => row?.company?.company_name,
      sortable: true,
      center: true,
      cell: (row) => <span style={{ color: 'purple', fontWeight: '600' }}>{row?.company?.company_name}</span>
    },
    {
      name: <strong>Company Email</strong>,
      selector: (row) => row?.company?.email,
      sortable: true,
      center: true
    },
    {
      name: 'Product Name',
      selector: (row) => row?.product_name,
      center: true,
      cell: (row) => <span className={'product_name_for' + row?.product_name} style={{ color: 'chocolate', fontWeight: '600' }}>{row?.product_name}</span>
    },
    {
      name: 'Expiry Date',
      selector: (row) => row?.Expire_date,
      cell: (row) => moment(row?.Expire_date).format("DD-MM-YYYY"),
      center: true
    },
    {
      name: 'Subscription Type',
      selector: (row) => <span style={{ fontWeight: '600', color: 'goldenrod' }}>{row?.subScription_type.toUpperCase() || "N/A"}</span>,
      center: true
    },
    {
      name: 'Number of users',
      selector: (row) => row?.number_of_users,
      center: true
    },
    {
      name: 'Status',
      selector: (row) => row?.company?.status,
      center: true,
      cell: (row) => {
        let status = row?.status
        if (status == 1) {
          return <span className="status_tags Approvedtags">Active</span>
        }
        return <span className="status_tags Processingtags">Deactive</span>

      }
    },
    {
      name: 'Actions',
      center: true,
      cell: (row) => (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <EditOutlined onClick={() => handleEdit(row)} />
        </MDTypography>
      ),
      right: true,
      width: '80px',
    },
  ];
  useEffect(() => {
    getTableData();
  }, [count]);

  const getTableData = async () => {
    try {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const resp = await fetch(`${URL_WITH_VERSION}accounts/subscribers-details`, requestOptions)
      const respdata = await resp.json();
      // console.log('respdata', respdata)
      if (respdata) {
        setState((prevState) => ({ ...prevState, rows: respdata }));
        setPending(false)
        setFilterdata(respdata)
      } else {
        openNotificationWithIcon("error", "Something went worng.");
      }
    } catch (err) {
      openNotificationWithIcon("error", err);
    }
  };

  const handleEdit = (row) => {
    setState((prevState) => ({ ...prevState, editData: row, isView: true }));
    setIsAddComapny(true);
  };

  const handleView = (row) => {
    setState((prevState) => ({ ...prevState, editData: row }));
    setIsAddComapny(true);
  };

  const { editData } = state;

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <MDInput
                  label="Search here"
                  style={{
                    flex: 1,
                    marginRight: '20px',
                    minWidth: '200px',
                    maxWidth: '600px',
                  }}
                  onChange={(e) => {
                    setfilterText(e.target.value)
                    handleSearch("")
                  }}
                />
                {/* <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddSub(true)}
                >
                  Add Subcription
                </MDButton> */}
              </div>
              <br />
              <br />
              <Card>
                <MDBox>
                  <DataTable columns={columns} data={filterData} pagination progressPending={pending} progressComponent={<CircularProgress />} />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

      {isAddCompany === true ? (
        <EditSubscriptionForm
          visible={true}
          onCancel={onCancel}
          onSave={(value) => {
            // console.log('value', value);
          }}
          editData={editData}
          isView={state?.isView}
        />
      ) : undefined}
      {isAddSub == true ? (
        <AddSubscriptionForm
          visible={true}
          onCancel={onCancel}
          onSave={(value) => {
            // console.log('value', value);
          }}
          editData={editData}
          isView={state?.isView}
        />
      ) : undefined}
    </>
  );
}

export default CompanyList;
