import axios from 'axios';
import { notification } from 'antd';

export const URL_WITH_VERSION = process.env.REACT_APP_URL;
export const WHATSAPP_BULK_MESSAGE_URL = process.env.REACT_APP_W_BULK_MESSAGE;



export const getAPICall = async (url, headers = '') => {
  let getHeaders = {};
  if (headers) {
    // headers = JSON.stringify(headers)
    getHeaders['headers'] = {
      'CONTENT-TYPE': 'application/json',
      crossorigin: 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
      'Access-Control-Allow-Headers': 'application/json',
      'Access-Control-Max-Age': 86400,
      Authorization: localStorage.getItem('token'),
    };
  } else {
    const token = localStorage.getItem('token');
    getHeaders['headers'] = {
      'CONTENT-TYPE': 'application/json',
      crossorigin: 'true',

      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
      'Access-Control-Allow-Headers': 'application/json',
      'Access-Control-Max-Age': 86400,

      Authorization: localStorage.getItem('token'),
    };
  }
  let callback = await axios.get(url, getHeaders);
  return callback['data'];
};

export const postAPICall = async (
  url,
  data,
  method = null,
  callback = null,
  headers = null,
) => {
  let postHeaders = {};
  if (headers) {
    // headers = JSON.stringify(headers)
    // let token = headers['auth_token'];
    let token = localStorage('token');
    delete headers['auth_token']; // remove it
    postHeaders['headers'] = {
      crossorigin: 'true',
      //"auth_token": token,
      Authorization: localStorage.getItem('token'),
    };
    postHeaders['Access-Control-Allow-Origin'] = '*';
  }

  if (typeof method === 'function') {
    callback = method;
    method = 'post';
  }
  return apiPostCall(method, url, data, callback, postHeaders);
};

const apiPostCall = (
  method,
  url,
  data,
  callback = null,
  postHeaders = null,
) => {
  axios({
    method: method,
    url: url,
    data: data,
  })
    .then((response) => {
      if (response.status === 200) {
        if (typeof callback === 'function') {
          return callback(response.data);
        } else {
          return response.data;
        }
      } else {
        openNotificationWithIcon(JSON.stringify(response));
      }
      // }else if (response.status === 401) {
      //   openNotificationWithIcon("error",'Invalid username or password');
      //   localStorage.removeItem("token")
      // } else if (response.status === 404) {
      //   openNotificationWithIcon("error",'User not found');
      // } else {
      //   openNotificationWithIcon("error",'An error occurred');
      // }
    })
    .catch((err) => {
      console.log('rrr', err);
      // openNotificationWithIcon('error', 'An error occurred');
    });
};

export const openNotificationWithIcon = (type, msg, duration = 2) => {
  let upperCaseTitle = (
    <h4 className={'notify-' + type}>
      {type.charAt(0).toUpperCase() + type.slice(1)}
    </h4>
  );

  notification.config({
    getContainer() {
      return document.body;
    },
  });
  notification.destroy();

  notification[type]({
    message: upperCaseTitle,
    description: msg,
    duration: duration,
  });
};
