// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

import DataTable from 'react-data-table-component';

// Data
import { useEffect, useState } from 'react';
import { getAPICall } from 'shared';
import { URL_WITH_VERSION } from 'shared';
import axios from 'axios';
import { RoomTwoTone } from '@mui/icons-material';
import { Button, Icon, Input, SvgIcon } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MDInputRoot from 'components/MDInput/MDInputRoot';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import AddCompanyForm from 'layouts/addCompany';
import { EditOutlined, EyeOutlined, FolderViewOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from 'shared';
import { Badge, Tooltip } from '@material-ui/core';
import { message } from 'antd';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
} from 'reactstrap';
// import SubscribeOceannMail from './subscribeMail';
import Swal from 'sweetalert2';
import { postAPICall } from 'shared';
import data from 'layouts/dashboard/components/Projects/data';

// eslint-disable-next-line react/prop-types
const ButtonTagS = ({ row }) => {
    const [userLoadin, setUserLoading] = useState(false);

    // eslint-disable-next-line react/prop-types
    if (row.is_vm_db_created === false) {
        return (
            <Button
                onClick={async (e) => {
                    setUserLoading(true);
                    const myHeaders = new Headers();
                    myHeaders.append('Content-Type', 'application/json');

                    const raw = JSON.stringify({
                        // eslint-disable-next-line react/prop-types
                        id: row.id,
                    });

                    const requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow',
                    };
                    let response = await fetch(
                        `${URL_WITH_VERSION}accounts/create-vm-db`,
                        requestOptions,
                    );
                    let data = await response.json();

                    await Swal.fire('VM DB Script started please wait one minute...');
                }}
            >
                {userLoadin === true ? (
                    <>
                        <Spinner size="sm">Creating VM DB</Spinner>
                        <span>Creating VM DB</span>
                    </>
                ) : (
                    <>Create VM DB</>
                )}
            </Button>
        );
    }
    return <Button disabled={true}> Create VM DB </Button>;
};

function ImportEmailList() {
    const [mailImport, setMailImport] = useState(false)
    const [state, setState] = useState({
        columns: [],
        rows: [],
        editData: undefined,
        isView: false,
    });
    const [isAddCompany, setIsAddComapny] = useState(false);
    const [filtered_items, setFiltereditems] = useState({});
    const [filterText, setfilterText] = useState('');
    const [pending, setPending] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [filterdata, setFilterData] = useState([]);
    const [ModalOpen, setModalOpen] = useState(false);
    const [ModalOpenData, setModalOpenData] = useState({});
    const [userLoading, setUserLoading] = useState({});
    const [importMailList, setImportMailList] = useState([])
    const prod =
        window.location.host.includes('.com') ||
            window.location.host.includes('localhost')
            ? false
            : true;

    // const filterdata = state?.rows?.filter((item) =>
    //   JSON.stringify(item).toLocaleLowerCase().includes(filterText.toLowerCase()),
    // )||[];

    function Copy(url) {
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        message.success('URL copied to clipboard');
    }

    const columns = [
        {
            name: <strong>S.no</strong>,
            selector: (row) => row?.import_email,
            sortable: true,
            center: 'true',

            cell: (row, index) => {
                return (
                    <p>{index + 1}</p>)
            },

            width: '80px',
        },
        {
            name: <strong>Email</strong>,
            selector: (row) => row?.import_email,
            sortable: true,
            center: 'true',
            // width: '150px',
        },
        // {
        //     name: 'Email Provider',
        //     selector: (row) => row?.client_email_provider,
        //     sortable: true,
        //     center: 'true',

        // },
        {
            name: 'Label Name',
            selector: (row) => row?.MailLabel,
            sortable: true,
            center: 'true',

        },


    ];

    useEffect(() => {
        // getTableData();
        showimportEmails()
    }, []);





    const onCancel = () => {
        setState((prevState) => ({
            ...prevState,
            editData: undefined,
            isView: false,
        }));
        setIsAddComapny(false);
    };

    const showimportEmails = async () => {
        setMailImport(true)
        try {
            let _method = 'post';
            let url = `${URL_WITH_VERSION}accounts/company-oceannmailaccount`
            // let payload = { id: row?.id }
            let payload

            postAPICall(url, payload, _method, (data) => {
                console.log(data?.data)
                setImportMailList(data.data)
                if (data) {
                    // openNotificationWithIcon(
                    //     'success',
                    //     'Import Mails Are  Successfully',
                    // );
                    onCancel();
                    form.resetFields();
                } else {
                    openNotificationWithIcon('error', data.msg);
                }
            });
            setPending(false);

        } catch (err) {
            setPending(false);
            // openNotificationWithIcon('error', err);
        }

        // setState((prevState) => ({ ...prevState, editData: row }));

    };


    return (
        <>
            {/* {ModalOpen === true ? (
                <>
                    <SubscribeOceannMail row={ModalOpenData} modalState={setModalOpen} />
                </>
            ) : (
                <></>
            )} */}
            <DashboardLayout>
                <DashboardNavbar />
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <MDInput
                                    label="Search here"
                                    style={{
                                        flex: 1,
                                        marginRight: '20px',
                                        minWidth: '200px',
                                        maxWidth: '600px',
                                    }}
                                    onChange={(e) => {
                                        setfilterText(e.target.value);
                                        setFilterData(
                                            importMailList.filter((item) =>
                                                JSON.stringify(item)
                                                    .toLocaleLowerCase()
                                                    .includes(e.target.value.toLocaleLowerCase()),
                                            ),
                                        );
                                    }}
                                />
                                {/* <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddComapny(true)}
                >
                  Add Company
                </MDButton> */}
                            </div>
                            <br />
                            <br />
                            <Card>
                                <MDBox>
                                    {/* {importMailList.map((data => {
                                        return (
                                            <>
                                                <div key={data?.id}>{data?.company_name}</ div>
                                                <DataTable
                                                    columns={columns}
                                                    data={data.oceanmailaccounts_set}
                                                    pagination
                                                    progressPending={pending}
                                                    progressComponent={<CircularProgress />}
                                                />
                                            </>
                                        )
                                    }))} */}
                                    <DataTable
                                        columns={columns}
                                        data={importMailList}
                                        pagination
                                        progressPending={pending}
                                        progressComponent={<CircularProgress />}
                                    />

                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>

        </>
    );
}

export default ImportEmailList;
