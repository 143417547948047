import PropTypes from 'prop-types';
import { Box, Button, Modal, Typography, List, ListItem, ListItemText } from "@mui/material";
import moment from 'moment';

function ChatModal({ isChatModalOpen, setIsChatModalOpen, chatData, selectedChat }) {
  return (
    <Modal open={isChatModalOpen} onClose={() => setIsChatModalOpen(false)}>
      <Box sx={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        width: 400, bgcolor: 'background.paper', border: '2px solid #1976d2', boxShadow: 24, p: 4,
      }}>
        <Typography variant="h6" component="h2">
          Chat History with {selectedChat}
        </Typography>

        {/* Chat Messages */}
        <List sx={{ maxHeight: 300, overflowY: 'auto', mt: 2 }}>
          {chatData && chatData.length>0 ? chatData.map((message) => (
            <ListItem key={message.id} sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: message.contact_number === selectedChat.contact_number ? 'flex-start' : 'flex-end',
              mb: 1,
              bgcolor: message.contact_number === selectedChat.contact_number ? '#f1f0f0' : '#d0f7d4',
              borderRadius: 2,
              padding: 1.5,
            }}>
              <Typography variant="caption" color="textSecondary">
                {moment(message.timestamp).format('DD MMM YYYY, HH:mm')}
              </Typography>
              <ListItemText style={{fontSize:'15px'}} primary={message.message_text} />
            </ListItem>
          )) : <span>Chat Not Started Yet.</span>}
        </List>

        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsChatModalOpen(false)}
          sx={{ mt: 2, color: '#fff' }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
}

// Define prop types for validation
ChatModal.propTypes = {
  isChatModalOpen: PropTypes.bool.isRequired,
  setIsChatModalOpen: PropTypes.func.isRequired,
  chatData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      contact_number: PropTypes.string.isRequired,
      contact_name: PropTypes.string.isRequired,
      message_text: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedChat: PropTypes.shape({
    contact_name: PropTypes.string,
    contact_number: PropTypes.string,
  }),
};

export default ChatModal;
