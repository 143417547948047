import React, { useState } from 'react';
import {
  Box, TextField, Autocomplete, Button, Typography,
  CircularProgress
} from '@mui/material';
import { WHATSAPP_BULK_MESSAGE_URL } from "shared";
import { postAPICall } from 'shared';
import { openNotificationWithIcon } from 'shared';
import SuccessMessageCards from './SuccessMessageCards';

const WhatsAppBulkMessageSender = () => {
  const [template, setTemplate] = useState('');
  const [message, setMessage] = useState('');
  const [numbers, setNumbers] = useState('');
  const [respdata, setRespData] = useState([]);
  const [error, setError] = useState(''); 
  const [pending, setPending] = useState(false);

  const templates = ["signup_now_template", "introduction", "new_options"];

  const handleTemplateChange = (event, value) => {
    setTemplate(value);
  };

  const handleTemplateInputChange = (event, value) => {
    setTemplate(value); 
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleNumbersChange = (event) => {
    setNumbers(event.target.value);
    if (event.target.value.trim()) {
      setError(''); 
    }
  };

  const handleSendMessages = () => {
    if (!numbers.trim()) { 
      setError('Phone numbers are required!');
      return;
    }

    try {
      setPending(true);

      let _method = 'post';
      let url = `${WHATSAPP_BULK_MESSAGE_URL}`;
      
      let payload = { template_name: template, message: message, numbers: numbers };


      if (template?.trim().length === 0 && message?.trim().length ===0 ) {
        setPending(false)
        return openNotificationWithIcon('error', "Please Select a Template or Type a message.");
      }

      if (numbers?.trim().length < 11  ) {
        setPending(false)
        return openNotificationWithIcon('error', "Please Enter Correct Mobile Number.");
      }
      
      postAPICall(url, payload, _method, (data) => {
        if (data) {
          setRespData(data);
          openNotificationWithIcon('success', data.message);
          setMessage('');
          setNumbers('');
          setTemplate('');
        setPending(false);

        } else {
          openNotificationWithIcon('error', data.msg);
        setPending(false);

        }
      });
    } catch (error) {
      openNotificationWithIcon('error', error.message);
      setPending(false);

    }
  };

  return (
    <>{pending ?
     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width:'100%' }}>
      <CircularProgress />
    </Box>
:      <Box
        sx={{
          width: 900,
          margin: 'auto',
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: 'white',
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          WhatsApp Bulk Message Sender
        </Typography>

        {respdata && respdata.length > 0 ? (
          <SuccessMessageCards data={respdata} setData={setRespData} />
        ) : (
          <>
            <Typography variant="body1">Select or Enter Template Name:</Typography>
            <Autocomplete
              freeSolo
              options={templates}
              value={template}
              onChange={handleTemplateChange} // Handles selection from dropdown
              onInputChange={handleTemplateInputChange} // Handles manual typing
              renderInput={(params) => (
                <TextField {...params} label="Select or type template name" variant="outlined" />
              )}
            />

            <Typography variant="body1">Write the Message:</Typography>
            <TextField
              multiline
              rows={3}
              placeholder="Message"
              value={message}
              onChange={handleMessageChange}
              variant="outlined"
            />

            <Typography variant="body1"><span style={{color:'red', fontSize:'20px'}}> *  </span>Enter Mobile Numbers (comma-separated):</Typography>
            <TextField
              multiline
              rows={2}
              placeholder="e.g. 911234567890, 14155238886"
              value={numbers}
              onChange={handleNumbersChange}
              variant="outlined"
              error={!!error} 
              helperText={error} 
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleSendMessages}
              sx={{ mt: 2, paddingY: 1.5, fontSize: '1rem', color: '#fff' }}
            >
              Send Messages
            </Button>
          </>
        )}
      </Box> }
    </>
  );
};

export default WhatsAppBulkMessageSender;
